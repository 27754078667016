import React, { useState, Suspense, useEffect, useRef } from 'react'
import useCardStyle from '../../hooks/useCardStyle'
import { Switch, Modal, InputNumber, Alert } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import useQuotationStepsQuery from '../../hooks/cms/useQuotationStepsQuery'
import useSignatureQuery from '../../hooks/cms/useSignatureQuery'
import useHouseYearQuery from '../../hooks/cms/useHouseYearQuery'
import usePaymentSchemeQuery from '../../hooks/backend/usePaymentSchemeQuery'
import useCustomerQuery from '../../hooks/backend/useCustomerQuery'
import useUpdateQuotationIsReadyMutation from '../../hooks/backend/useUpdateQuotationIsReadyMutation'
import withHandleQuery from '../../hoc/withHandleQuery'
import Spinner from '../../components/spinner/Spinner'
import useQuotationPdfQuery from '../../hooks/cms/useQuotationPdfQuery'
import QuotationInvoiceView from '../../components/cards/QuotationInvoiceView'
import html2canvas from 'html2canvas'
import { jsPDF } from "jspdf";
import * as provisionAction from '../../redux/actions/provisionAction'
import * as quotationAction from '../../redux/actions/quotationAction'
import { isSafari } from 'react-device-detect'
import { saveAs } from 'file-saver'
import { PrinterOutlined, CheckOutlined } from '@ant-design/icons'
import { occasionCardQuery, buybackCardQuery, ctdChecksComponentQuery } from '../../lib/queries/cms/queries'
import sanityClient from '../../lib/client.js'
import { PAY_DEBTOR } from '../../queries/back-end/queries.js'
import { graphqlMutation } from '../../lib/common/graphql.js'
import { isIOS } from 'react-device-detect'

const DeliveryAddress = React.lazy(() => import('../../components/cards/order/DeliveryAddress'))
const AdditionalConditions = React.lazy(() => import('../../components/cards/order/AdditionalConditions'))
const Remarks = React.lazy(() => import('../../components/cards/order/Remarks'))
const PaymentMethod = React.lazy(() => import('../../components/cards/order/PaymentMethod'))
const ConfirmOrder = React.lazy(() => import('../../components/cards/order/ConfirmOrder'))
const OrderSpecialPromotions = React.lazy(() => import('../../components/cards/order/OrderSpecialPromotions'))
const OrderProduct = React.lazy(() => import('../../components/cards/order/OrderProduct'))
const Signature = React.lazy(() => import('../../components/cards/Signature'))
const UploadOrderFile = React.lazy(() => import('../../components/cards/UploadOrderFile'))
const Documents = React.lazy(() => import('../../components/cards/Documents'))
const Occasion = React.lazy(() => import('../../components/cards/order/Occasion'))
const Buyback = React.lazy(() => import('../../components/cards/order/Buyback'))
const QuotationAdditionalExtraServices = React.lazy(() => import('../../components/cards/quotation-options/QuotationAdditionalExtraServices'))

const Order = (props) => {

  const {
    dataHoc,
    myHomeVisitScreenUrl,
    ctdCheckScreenUrl,
    history
  } = props

  const quotations = useSelector(state => state.quotations)
  const languages = useSelector(state => state.languages)
  const products = useSelector(state => state.products)

  const [paymentMethod, setpaymentMethod] = useState(quotations?.quotation?.PaymentScheme ? [
    quotations?.quotation?.PaymentScheme?.id.toString(),
    quotations?.quotation?.PaymentScheme?.descriptions.filter((description) => {
      return description.language === languages.language.split('_')[0]
    })[0]?.value
  ] : [])
  const [loading, setLoading] = useState(false)
  const [showSpecialPromotionCard, setSpecialPromotionCard] = useState(false)
  const [showAdditionalDiscount, setAdditionalDiscount] = useState(false)
  const [subvention, setSubvention] = useState(quotations?.quotation?.subvention ? quotations?.quotation?.subvention : 0)
  const [deliveryTime, setDeliveryTime] = useState()
  const [showDocuments, setShowDocuments] = useState('')
  const [occasionCMSData, setOccasionCMSData] = useState(null)
  const [buybackCMSData, setBuybackCMSData] = useState(null)
  const [ctdButtonStyle, setCtdButtonStyle] = useState('')
  const [ctdCheckLabels, setCtdCheckLabels] = useState(null)
  const [orderCode, setOrderCode] = useState(
    quotations?.quotation?.ExtraServiceResponse.length > 0 ?
      quotations?.quotation?.ExtraServiceResponse.filter((element) => {
        return element && element?.properties[0]?.property === 3
      }).map((service) => {
        return {
          id: service?.id,
          description: `(${service.code}) ${service.descriptions.find((description) => { if (description.language === languages.language.split('_')[0]) { return description } return null })?.value}`,
          price: service.price,
          quantity: service.quantity,
          serviceId: service.id
        }
      })
      :
      []
  )
  const [understand, setUnderstand] = useState(false)

  // pay debtor
  let refPaymentAmount = useRef();
  const [paydebtorMessage, setPaydebtorMessage] = useState("")
  const [paydebtorModal, setPaydebtorModal] = useState(false);

  // additional conditions
  const [showAdditionalConditions, setShowAdditionalConditions] = useState(false)
  const [additionalConditions, setAdditionalConditions] = useState()
  const [additionalConditionsSignature, setAdditionalConditionsSignature] = useState(null)

  const impersonates = useSelector(state => state.impersonates)
  const answers = useSelector(state => state.answers)
  const logins = useSelector(state => state.logins)
  const filters = useSelector(state => state.filters)

  const dispatch = useDispatch()
  const setProvisionVisibility = (value) => dispatch(provisionAction.setProvisionVisibility(value))
  const setQuotation = (quotation) =>
    dispatch(quotationAction.setQuotation(quotation))

  const [remarks, setRemarks] = useState(quotations.quotation.remark)

  const [houseYear, setHouseYear] = useState(quotations.quotation?.houseYear ? quotations.quotation?.houseYear : null)

  /**
 * Creates a new canvas.
 * This canvas is not appended to any node in the document tree 
 */
  const createCanvas = (canvasName) => {
    const canvas = document.createElement('canvas');
    canvas.id = canvasName
    canvas.width = 500;
    canvas.height = 200;

    document.body.appendChild(canvas)

    const newCanvas = document.getElementById(canvasName); //find new canvas we created
    const context = newCanvas.getContext('2d');

    context.fillStyle = "white";
    context.fillRect(0, 0, 400, 200);

    Array.from(document.querySelectorAll(`#${canvasName}`))
      .forEach(function (val) {
        val.style.display = 'none';
      });

    return canvas
  }

  // CTD data from Sanity CMS
  useEffect(() => {
    setLoading(true)
    const query = ctdChecksComponentQuery
    const params = { language: languages.language === "en" ? 'en_UK' : languages.locale }

    sanityClient.fetch(query, params)
      .then((data) => {
        setCtdCheckLabels(
          Object.assign(
            data.ctdCheckProductEntry.find((product) => {
              return product.product.backendId === products.productId
            }),
            { ctdPdfHeader: data?.ctdPdfHeader },
            { ctdPdfApprovalComment: data.ctdPdfApprovalComment },
            { installationInformation: data.installationInformation }
          )
        )
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })

  }, [])

  // fetch Ocasion Component from Sanity CMS
  // fetch Buyback Component from Sanity CMS
  useEffect(() => {
    const queryOccasion = occasionCardQuery
    const paramsOccasion = { language: languages.locale }
    setLoading(true)

    const fetchOccasionData = async () => {
      await sanityClient.fetch(queryOccasion, paramsOccasion)
        .then((data) => {
          setOccasionCMSData(data)
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
        })
    }

    fetchOccasionData()

    const queryBuyback = buybackCardQuery
    const paramsBuyback = { language: languages.locale }
    setLoading(true)
    const fetchBuybackData = async () => {
      await sanityClient.fetch(queryBuyback, paramsBuyback)
        .then((data) => {
          setBuybackCMSData(data)
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
        })
    }

    fetchBuybackData()

  }, [languages.locale])

  useEffect(() => {
    if (occasionCMSData && buybackCMSData) {
      if (history.location.search !== '?view=true') {
        showPopUp(false, "quotation")
      }
      setProvisionVisibility(true)
    }
  }, [occasionCMSData, buybackCMSData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const isCtcChecksDone = history.location.search.includes('?ctd=done')

    if (isCtcChecksDone) {
      setCtdButtonStyle("btn btn-warning disable")
    } else {
      switch (quotations.quotation.CTDRequest?.Status.id) {
        // approved
        case 4:
          setCtdButtonStyle("btn btn-success disable")
          break;
        // request changes
        case 3:
          setCtdButtonStyle("btn btn-danger")
          break;
        // in progress
        case 2 || isCtcChecksDone:
          setCtdButtonStyle("btn btn-warning disable")
          break;
        // new
        case 1:
          setCtdButtonStyle("btn btn-warning disable")
          break;
        default:
          setCtdButtonStyle("btn btn-orange")
          break;
      }
    }

  }, [])

  const { cardStyle } = useCardStyle()

  const quotationStepsLabels = dataHoc[0] !== undefined && dataHoc[0].quotationStepsLabels
  const paymentSchemeData = dataHoc[1] !== undefined && dataHoc[1].paymentSchemeData
  const signatureLabels = dataHoc[2] !== undefined && dataHoc[2].signatureLabels
  const houseYearLabels = dataHoc[3] !== undefined && dataHoc[3].houseYearLabels
  const customerQueryData = dataHoc[4] !== undefined && dataHoc[4].customerQueryData
  const quotationPdfLabels = dataHoc[5] !== undefined && dataHoc[5].quotationPdfLabels
  const updateQuotationIsReadyMutation = dataHoc[6] !== undefined && dataHoc[6].updateQuotationIsReadyMutation

  const productName = quotations.quotation?.WizardCompositeKey.Model.name
  const versionNumber = quotations.quotation?.compositeKeyId
  const discount = quotations.quotation?.discount
  const totalPrice = quotations.quotation?.quotationPrice
  const modelPicture = products.productId !== 24 ? quotations.quotation?.WizardCompositeKey.Model.picture : `${process.env.REACT_APP_BACKEND_PROXY_SERVER}saninet/${quotations.quotation.id}/thumbnail`

  const preOrderAdditionalExtraServicesLabels = {
    cardAdditionalCodesTitle: quotationStepsLabels.lables.cardAdditionalCodesTitle,
    cardAdditionalCodesTableDescription: quotationStepsLabels.lables.cardAdditionalCodesTableDescription,
    cardAdditionalCodesTableQuantity: quotationStepsLabels.lables.cardAdditionalCodesTableQuantity,
    cardAdditionalCodesTablePrice: quotationStepsLabels.lables.cardAdditionalCodesTablePrice,
    cardAdditionalCodesSelectButton: quotationStepsLabels.lables.cardAdditionalCodesSelectButton,
    cardAdditionalCodesDeleteCodesButton: quotationStepsLabels.lables.cardAdditionalCodesDeleteCodesButton,
    cardAdditionalCodesPopupTitle: quotationStepsLabels.lables.cardAdditionalCodesPopupTitle,
    cardAdditionalCodesPopupOption: quotationStepsLabels.lables.cardAdditionalCodesPopupOption,
    cardAdditionalCodesPopupDropdownText: quotationStepsLabels.lables.cardAdditionalCodesPopupDropdownText,
    cardAdditionalCodesPopupButton: quotationStepsLabels.lables.cardAdditionalCodesPopupButton,
    cardAdditionalCodesPopupErrorMessage: quotationStepsLabels.lables.cardAdditionalCodesPopupErrorMessage
  }

  // Signature - Customer
  const [fileSignature, setFileSignature] = useState(null)
  const [signatureName, setSignatureName] = useState()

  // Signature - Salesman
  const [fileSalesmanSignature, setfileSalesmanSignature] = useState(null)

  const onChangeSwitch = (checked) => {
    setSpecialPromotionCard(checked)
  }

  const showPopUp = (printQuotationPdf, pdfType) => {
    Modal.info({
      icon: <></>,
      maskClosable: true,
      width: 900,
      bodyStyle: { width: "900px", overflow: "auto" },
      content:
        <>
          <div className="w-100 d-flex justify-content-center">
            <div className="btn btn-orange" style={{ width: '275px' }} onClick={() => handlePrint(pdfType)}>
              <PrinterOutlined className="btn d-flex justify-content-center" style={{
                fontSize: '30px'
              }} />
              {pdfType !== "quotation" && quotationPdfLabels.orderLabel}
            </div>
            {
              additionalConditions &&
              <div className="btn btn-orange" style={{ width: '275px' }} onClick={() => handlePrint2(pdfType)}>
                <PrinterOutlined className="btn d-flex justify-content-center" style={{
                  fontSize: '30px'
                }} />
                {additionalConditionsText(languages.locale)}
              </div>
            }
          </div>
          <QuotationInvoiceView
            additionalConditionsTitle={additionalConditionsText(languages.locale)}
            additionalConditions={additionalConditions}
            additionalConditionsSignature={additionalConditionsSignature}
            filters={filters}
            products={products}
            customerQueryData={customerQueryData}
            productName={productName}
            versionNumber={versionNumber}
            modelPicture={modelPicture}
            totalPrice={totalPrice - discount}
            quotationStepsLabels={quotationStepsLabels}
            quotations={quotations}
            answers={answers}
            languages={languages}
            printQuotationPdf={printQuotationPdf}
            history={history}
            setLoading={setLoading}
            quotationPdfLabels={quotationPdfLabels}
            pdfType={pdfType}
            paymentMethod={paymentMethod[1]}
            remarks={remarks}
            deliveryTime={deliveryTime}
            signatureTitle={signatureLabels.title}
            fileSignature={fileSignature}
            fileSalesmanSignature={fileSalesmanSignature}
            logins={logins}
            subvention={subvention}
            deliveryTimeLabel={quotationStepsLabels.lables.deliveryTime}
            impersonates={impersonates}
            signatureName={signatureName}
            updateQuotationIsReadyMutation={updateQuotationIsReadyMutation}
            occasionLable={occasionCMSData?.cardName}
            buybackLable={buybackCMSData?.cardName}
            ctdCheckLabels={ctdCheckLabels}
          />
        </>
      ,
      okText: <div data-cy="quotation-pdf"><CheckOutlined className="btn d-flex" /></div>,
    })
  }

  const saveOffer = () => {
    showPopUp(true, "quotation")
  }

  const generatePaymentLink = async () => {
    const payDebtorInput = {
      quotationId: quotations.quotation.id,
      amount: Number(refPaymentAmount?.current?.value)
    }
    setLoading(true)
    try {
      const { data } = await graphqlMutation(
        payDebtorInput,
        PAY_DEBTOR,
        process.env.REACT_APP_BACKEND_GRAPHQL_ENDPOINT
      );

      if (data?.errors) {
        setPaydebtorMessage(data.errors[0].message)
      } else {
        setPaydebtorMessage(data.data.payDebtor)
      }
    } catch (error) {
      setPaydebtorMessage(error)
    }

    setLoading(false)
  }

  const showPaymentLinkModal = () => {
    setPaydebtorModal(true)
  }

  const handlePrint = async (pdfType) => {
    const pdfLabel = pdfType === "quotation" ? quotationPdfLabels.quotationLabel : quotationPdfLabels.orderLabel

    const element = document.getElementById("overview-pdf")

    setLoading(true)

    var w = (element.offsetWidth);
    var h = (element.offsetHeight);
    await html2canvas(element, {
      letterRendering: 1,
      allowTaint: true,
      dpi: 300,
      scale: 3,
      useCORS: true,
    }).then(function (canvas) {
      setLoading(false)
      var img = canvas.toDataURL("image/jpeg", 1);
      var doc = new jsPDF('p', 'px', [w, h]);
      doc.addImage(img, 'JPEG', 0, 0, w, h);

      if (isSafari) {
        canvas.toBlob((blob) => {
          saveAs(blob, "mobilae.png")
        })
      } else {
        doc.save(`${pdfLabel}.pdf`)
      }
    })
  }

  const handlePrint2 = async (pdfType) => {
    const pdfLabel = pdfType === "quotation" ? quotationPdfLabels.quotationLabel : quotationPdfLabels.orderLabel

    const additionalConditionsElement = document.getElementById("additional-conditions")

    setLoading(true)

    // additional conditions
    var w2 = (additionalConditionsElement.offsetWidth);
    var h2 = (additionalConditionsElement.offsetHeight);
    await html2canvas(additionalConditionsElement, {
      letterRendering: 1,
      allowTaint: true,
      dpi: 300,
      scale: 3,
      useCORS: true,
    }).then(function (canvas) {
      setLoading(false)
      var img = canvas.toDataURL("image/jpeg", 1);
      var doc = new jsPDF('p', 'px', [w2, h2]);
      doc.addImage(img, 'JPEG', 0, 0, w2, h2);

      if (isSafari) {
        canvas.toBlob((blob) => {
          saveAs(blob, "mobilae2.png")
        })
      } else {
        doc.save(`${pdfLabel}2.pdf`)
      }
    })
  }

  // Translation hard coded. Waiting for migration to myMobilae 2.0
  const additionalConditionsText = (locale) => {
    switch (locale) {
      case "en_UK":
        return "Additional conditions"
      case "nl_NL":
      case "nl_BE":
        return "Aanvullende voorwaarden"
      case "it_IT":
        return "Condizioni supplementari"
      case "fr_FR":
      case "fr_BE":
        return "Conditions complémentaires"
      case "de_DE":
        return "Zusatzvereinbarungen"
      default:
        return "Additional conditions"
    }
  }

  // Translation hard coded. Waiting for migration to myMobilae 2.0
  const generatePaymentLinkTitle = (locale) => {
    switch (locale) {
      case "en_UK":
        return "Generate a payment link"
      case "nl_NL":
      case "nl_BE":
        return "Genereer een betalingslink"
      case "it_IT":
        return "Genera un link di pagamento"
      case "fr_FR":
      case "fr_BE":
        return "Générer un lien de paiement"
      case "de_DE":
        return "Zahlungslink generieren"
      default:
        return "Generate a payment link"
    }
  }

  // Translation hard coded. Waiting for migration to myMobilae 2.0
  const linkCopiedAlert = (locale) => {
    switch (locale) {
      case "en_UK":
        return "Link copied with success"
      case "nl_NL":
      case "nl_BE":
        return "Link succesvol gekopieerd"
      case "it_IT":
        return "Link copiato con successo"
      case "fr_FR":
      case "fr_BE":
        return "Lien copié avec succès"
      case "de_DE":
        return "Link erfolgreich kopiert"
      default:
        return "Link copied with success"
    }
  }

  // Translation hard coded. Waiting for migration to myMobilae 2.0
  const copyButtonLabel = (locale) => {
    switch (locale) {
      case "en_UK":
        return "Copy payment link"
      case "nl_NL":
      case "nl_BE":
        return "Kopieer betalingslink"
      case "it_IT":
        return "Copia link di pagamento"
      case "fr_FR":
      case "fr_BE":
        return "Copier le lien de paiement"
      case "de_DE":
        return "Zahlungslink kopieren"
      default:
        return "Copy payment link"
    }
  }

  // Translation hard coded. Waiting for migration to myMobilae 2.0
  const openLinkButtonLabel = (locale) => {
    switch (locale) {
      case "en_UK":
        return "Open payment link"
      case "nl_NL":
      case "nl_BE":
        return "Open betalingslink"
      case "it_IT":
        return "Apri link di pagamento"
      case "fr_FR":
      case "fr_BE":
        return "Ouvrir le lien de paiement"
      case "de_DE":
        return "Zahlungslink öffnen"
      default:
        return "Open payment link"
    }
  }

  // Translation hard coded. Waiting for migration to myMobilae 2.0
  const loadingLabel = (locale) => {
    switch (locale) {
      case "en_UK":
        return "loading"
      case "nl_NL":
      case "nl_BE":
        return "laden"
      case "it_IT":
        return "caricamento"
      case "fr_FR":
      case "fr_BE":
        return "chargement"
      case "de_DE":
        return "Laden"
      default:
        return "loading"
    }
  }

  // Translation hard coded. Waiting for migration to myMobilae 2.0
  const generatePaymentLinkButtonLabel = (locale) => {
    switch (locale) {
      case "en_UK":
        return "Generate payment link"
      case "nl_NL":
      case "nl_BE":
        return "Genereer betalingslink"
      case "it_IT":
        return "Genera link di pagamento"
      case "fr_FR":
      case "fr_BE":
        return "Générer un lien de paiement"
      case "de_DE":
        return "Zahlungslink generieren"
      default:
        return "Generate payment link"
    }
  }

  // Translation hard coded. Waiting for migration to myMobilae 2.0
  const generateStairliftForm = (locale) => {
    switch (locale) {
      case "en_UK":
        return "https://my-environment-assets.s3.eu-central-1.amazonaws.com/pdf/orderforms/nl/sta-fillable-form-nl.pdf"
      case "nl_NL":
        return "https://my-environment-assets.s3.eu-central-1.amazonaws.com/pdf/orderforms/nl/sta-fillable-form-nl.pdf"
      case "nl_BE":
        return "https://my-environment-assets.s3.eu-central-1.amazonaws.com/pdf/orderforms/nl-be/sta-fillable-form-be.pdf"
      case "it_IT":
        return "https://my-environment-assets.s3.eu-central-1.amazonaws.com/pdf/orderforms/it/sta-fillable-form-it.pdf"
      case "fr_FR":
        return "https://my-environment-assets.s3.eu-central-1.amazonaws.com/pdf/orderforms/fr/sta-fillable-form-fr.pdf"
      case "fr_BE":
        return "https://my-environment-assets.s3.eu-central-1.amazonaws.com/pdf/orderforms/fr-be/sta-fillable-form-bf.pdf"
      case "de_DE":
        return "https://my-environment-assets.s3.eu-central-1.amazonaws.com/pdf/orderforms/de/sta-fillable-form-de.pdf"
      default:
        return "https://my-environment-assets.s3.eu-central-1.amazonaws.com/pdf/orderforms/nl/sta-fillable-form-nl.pdf"
    }
  }

  // Translation hard coded. Waiting for migration to myMobilae 2.0
  const generateStairliftFormLabel = (locale) => {
    switch (locale) {
      case "en_UK":
        return "Stairlift Form"
      case "nl_NL":
      case "nl_BE":
        return "Trapliftformulier"
      case "it_IT":
        return "Modulo per montascale"
      case "fr_FR":
      case "fr_BE":
        return "Formulaire de monte-escalier"
      case "de_DE":
        return "Treppenlift-Formular"
      default:
        return "Stairlift Form"
    }
  }

  const info = () => {

    const stairLiftForm = generateStairliftForm(languages.locale)

    const stairLiftFormSource = isIOS ? `https://drive.google.com/viewerng/viewer?url=${stairLiftForm}?pid=explorer&efh=false&a=v&chrome=false&embedded=true` : stairLiftForm

    Modal.info({
      className: 'agreement-modal',
      title: generateStairliftFormLabel(languages.locale),
      content:
        <div style={{
          width: '100%',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          height: '100%'
        }}>
          <iframe src={stairLiftFormSource}
            style={{
              width: '100%',
              height: '600px'
            }}
            title="Agreement Modal">
          </iframe>
        </div>
      ,
      okText: <CheckOutlined className="btn d-flex" />,
    });
  }

  return (
    <Suspense fallback={null}>
      <div className={`card-${!logins.isMobile ? 'deck' : ''} p-3 p-lg-5 pt-0`}>

        {/* Buttons */}
        <div className="w-100">
          <div className='d-flex justify-content-center'>
            <div className="btn btn-orange" style={{ width: '275px' }} onClick={() => showPopUp(false, "quotation")} >{quotationStepsLabels.lables.quotationPdfButton}</div>
            <div className="btn btn-orange" style={{ width: '275px' }} onClick={() => saveOffer()} >{quotationStepsLabels.lables.saveButton}</div>
            <div className="btn btn-orange" style={{ width: '275px' }} onClick={() => showPopUp(false, "order")} >PDF</div>
            <div className={ctdButtonStyle} style={{ width: '275px', margin: '5px' }} onClick={() => history.push({
              pathname: ctdCheckScreenUrl,
              ...(quotations.quotation.CTDRequest && { search: `?ctd=${quotations.quotation.CTDRequest.id}` })
            })} >
              CTD
            </div>
            <div className="btn btn-orange" style={{ width: '275px' }} onClick={() => showPaymentLinkModal()} >{generatePaymentLinkButtonLabel(languages.locale)}
            </div>
          </div>
          {
            products.productId === 10 &&
            <div className='w-100 d-flex justify-content-center'>
              <div className="btn btn-dark-blue" style={{ height: '50px', alignContent: "center" }} onClick={info} >
                <div>
                  {generateStairliftFormLabel(languages.locale)}
                </div>
              </div>
            </div>
          }
        </div>

        <div className="card border-0" style={cardStyle}>
          <OrderProduct
            filters={filters}
            productName={productName}
            discount={discount}
            totalPrice={totalPrice}
            modelPicture={modelPicture}
            cardProductSpecifications={quotationStepsLabels.lables.cardProductSpecifications}
            cardProductColorOptionOne={quotationStepsLabels.lables.cardProductColorOptionOne}
            cardProductColorOptionTwo={quotationStepsLabels.lables.cardProductColorOptionTwo}
            cardProductExtraServiceOptionOne={quotationStepsLabels.lables.cardProductExtraServiceOptionOne}
            cardProductExtraServiceOptionTwo={quotationStepsLabels.lables.cardProductExtraServiceOptionTwo}
            cardProductAdditionalCodesTitle={quotationStepsLabels.lables.cardProductAdditionalCodesTitle}
            cardProductAdditionalCodesTableDescription={quotationStepsLabels.lables.cardProductAdditionalCodesTableDescription}
            cardProductAdditionalCodesTableQuantity={quotationStepsLabels.lables.cardProductAdditionalCodesTableQuantity}
            cardProductAdditionalCodesTablePrice={quotationStepsLabels.lables.cardProductAdditionalCodesTablePrice}
            questions={quotationStepsLabels.lables.answers}
          />

          <div className="card border-0" style={cardStyle}>
            <QuotationAdditionalExtraServices
              data={preOrderAdditionalExtraServicesLabels}
              setOrderCode={setOrderCode}
              orderCode={orderCode}
              isOrderScreen={true}
            />
          </div>

        </div>

        <div className="card border-0" style={cardStyle}>
          {
            languages.language !== "en" &&
            <PaymentMethod
              paymentMethodDropdownValues={paymentSchemeData.paymentSchemes}
              cardPaymentMethodDropdownInfoMessage={quotationStepsLabels.lables.cardPaymentMethodDropdownInfoMessage}
              cardPaymentMethodTitle={quotationStepsLabels.lables.cardPaymentMethodTitle}
              cardPaymentMethodDropdownText={quotationStepsLabels.lables.cardPaymentMethodDropdownText}
              cardPaymentMethodSubventionValue={quotationStepsLabels.lables.cardPaymentMethodSubventionValue}
              deliveryTimeLabel={quotationStepsLabels.lables.deliveryTime}
              paymentMethod={paymentMethod}
              setpaymentMethod={setpaymentMethod}
              setSubvention={setSubvention}
              subvention={subvention}
              deliveryTime={deliveryTime}
              setDeliveryTime={setDeliveryTime}
              products={products}
              setHouseYear={setHouseYear}
              houseYear={houseYear}
              houseYearLabels={houseYearLabels}
            />
          }

          {
            products.productId == 23 &&
            <Occasion
            />
          }

          {
            products.productId == 23 &&
            <Buyback
            />
          }

          {
            impersonates.isImpersonate &&
            <Switch className="mt-3" defaultChecked={false} onChange={onChangeSwitch} style={{ maxWidth: '50px' }} />
          }

          <OrderSpecialPromotions
            cardSpecialPromotionsTitle={quotationStepsLabels.lables.cardSpecialPromotionsTitle}
            cardSpecialPromotionsPromoCode={quotationStepsLabels.lables.cardSpecialPromotionsPromoCode}
            cardSpecialPromotionsPromoCodeMessage={quotationStepsLabels.lables.cardSpecialPromotionsPromoCodeMessage}
            showAdditionalDiscount={showAdditionalDiscount}
            cardSpecialPromotionsAdditionalDiscount={quotationStepsLabels.lables.cardSpecialPromotionsAdditionalDiscount}
            cardSpecialPromotionsAdditionalDiscountMessage={quotationStepsLabels.lables.cardSpecialPromotionsAdditionalDiscountMessage}
            setAdditionalDiscount={setAdditionalDiscount}
            cardSpecialPromotionsInfoMessage={quotationStepsLabels.lables.cardSpecialPromotionsInfoMessage}
            cardSpecialPromotionsButton={quotationStepsLabels.lables.cardSpecialPromotionsButton}
            showSpecialPromotionCard={showSpecialPromotionCard}
          />
          <Remarks
            cardTitle={quotationStepsLabels.lables.cardRemarksTitle}
            placeholder={quotationStepsLabels.lables.cardRemarksText}
            setRemarks={setRemarks}
            remarks={remarks}
            quotations={quotations}
            setQuotation={setQuotation}
          />

          {/* additional conditions */}
          <button
            className="btn btn-dark-blue"
            style={{ marginTop: "16px" }}
            onClick={() => {
              setShowAdditionalConditions(!showAdditionalConditions)
              setFileSignature(null)
              setfileSalesmanSignature(null)
              setUnderstand(false)
              setAdditionalConditionsSignature(null)
            }}
          >
            {additionalConditionsText(languages.locale)}
          </button>
          {
            showAdditionalConditions &&
            <AdditionalConditions
              setAdditionalConditions={setAdditionalConditions}
              cardTitle={additionalConditionsText(languages.locale)}
              setAdditionalConditionsSignature={setAdditionalConditionsSignature}
              signatureLabels={signatureLabels}
              createCanvas={createCanvas}
            />
          }

        </div>

        <div className="card border-0" style={cardStyle}>

          <Signature
            setFileSignature={setFileSignature}
            setfileSalesmanSignature={setfileSalesmanSignature}
            signatureLabels={signatureLabels}
            customerQueryData={customerQueryData}
            setSignatureName={setSignatureName}
            cardConfirmOrderContent={quotationStepsLabels.lables.cardConfirmOrderContent}
            termsAndConditionsId={quotationStepsLabels.lables.termsAndConditionsId}
            cardTermsAndConditionsPopupTitle={quotationStepsLabels.lables.cardTermsAndConditionsPopupTitle}
            cardTermsAndConditionsPopupPdf={quotationStepsLabels.lables.cardTermsAndConditionsPopupPdf}
            cardTermsAndConditionsBtn={quotationStepsLabels.lables.cardTermsAndConditionsBtn}
            cardConfirmOrderCheckBox={quotationStepsLabels.lables.cardConfirmOrderCheckBox}
            employeeName={logins.employeeName}
            impersonates={impersonates}
            setUnderstand={setUnderstand}
            understand={understand}
            createCanvas={createCanvas}
          />

          <UploadOrderFile quotationId={quotations.quotation.quotationId ? quotations.quotation.quotationId : quotations.quotation.id} />
          <Documents setShowDocuments={setShowDocuments} />
          <ConfirmOrder
            additionalConditions={additionalConditions}
            products={products}
            cardConfirmOrderTitle={quotationStepsLabels.lables.cardConfirmOrderTitle}
            cardConfirmOrderContent={quotationStepsLabels.lables.cardConfirmOrderContent}
            cardConfirmOrderSendButton={quotationStepsLabels.lables.cardConfirmOrderSendButton}
            cardConfirmOrderPopupTitle={quotationStepsLabels.lables.cardConfirmOrderPopupTitle}
            cardConfirmOrderPopupOkButton={quotationStepsLabels.lables.cardConfirmOrderPopupOkButton}
            cardConfirmOrderPopupCancelButton={quotationStepsLabels.lables.cardConfirmOrderPopupCancelButton}
            cardConfirmOrderPopupContent={quotationStepsLabels.lables.cardConfirmOrderPopupContent}
            myHomeVisitScreenUrl={myHomeVisitScreenUrl}
            cardConfirmOrderSuccessMessage={quotationStepsLabels.lables.cardConfirmOrderSuccessMessage}
            cardConfirmOrderErrorMessage={quotationStepsLabels.lables.cardConfirmOrderErrorMessage}
            paymentMethod={paymentMethod}
            remarks={remarks}
            fileSignature={fileSignature}
            loading={loading}
            houseYear={houseYear}
            pdfAssets={[quotationPdfLabels.pdfCoverPage].concat(quotationPdfLabels.pdfOrderGeneralConditions)}
            setProvisionVisibility={setProvisionVisibility}
            languages={languages}
            content={<QuotationInvoiceView
              additionalConditionsTitle={additionalConditionsText(languages.locale)}
              additionalConditions={additionalConditions}
              additionalConditionsSignature={additionalConditionsSignature}
              filters={filters}
              products={products}
              customerQueryData={customerQueryData}
              productName={productName}
              versionNumber={versionNumber}
              modelPicture={modelPicture}
              totalPrice={totalPrice - discount}
              quotationStepsLabels={quotationStepsLabels}
              quotations={quotations}
              answers={answers}
              languages={languages}
              printQuotationPdf={false}
              history={history}
              setLoading={setLoading}
              quotationPdfLabels={quotationPdfLabels}
              pdfType={'order'}
              paymentMethod={paymentMethod[1]}
              remarks={remarks}
              deliveryTime={deliveryTime}
              signatureTitle={signatureLabels.title}
              signatureName={signatureName}
              fileSignature={fileSignature}
              fileSalesmanSignature={fileSalesmanSignature}
              logins={logins}
              subvention={subvention}
              deliveryTimeLabel={quotationStepsLabels.lables.deliveryTime}
              impersonates={impersonates}
              updateQuotationIsReadyMutation={updateQuotationIsReadyMutation}
              occasionLable={occasionCMSData?.cardName}
              buybackLable={buybackCMSData?.cardName}
              ctdCheckLabels={ctdCheckLabels}
            />}
          />
          <DeliveryAddress
            cardTitle={quotationStepsLabels.lables.cardDeliveryAddressTitle}
            editButton={quotationStepsLabels.lables.cardDeliveryAddressEditButton}
            successMessageContent={quotationStepsLabels.lables.cardDeliveryAddressSuccessMessageContent}
            customerQueryData={customerQueryData}
          />
        </div>
        {/* Generate Payment Link Modal */}
        <Modal
          maskClosable={true}
          title={generatePaymentLinkTitle(languages.locale)}
          width={900}
          style={{ width: '900px' }}
          bodyStyle={{ width: "900px", overflow: "auto" }}
          open={paydebtorModal}
          onCancel={() => { setPaydebtorModal(false); setPaydebtorMessage("") }}
          footer={null}
        >
          <div className='d-flex align-items-center'>
            <div className='d-flex h-25 w-25'>
              <InputNumber
                ref={refPaymentAmount}
                className='w-50 mr-3'
                placeholder="Amout"
                min={0}
                max={100000}
                defaultValue={1}
              />
              <button
                className="btn btn-orange d-flex"
                onClick={() => generatePaymentLink()}
                disabled={loading}
              >
                <CheckOutlined />
              </button>
            </div>
            {paydebtorMessage && !loading &&
              <div className='d-flex justify-content-center align-items-center'>
                <div className="ml-5">
                  <div
                    className=" d-flex justify-content-center align-items-center border border-white rounded"
                    style={{
                      transition: 'background-color 0.3s ease',
                      cursor: 'default'
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = '#D3D3D3'; // Light gray in hex
                      e.target.style.cursor = 'pointer';
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = '';
                      e.target.style.cursor = 'default';
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(paydebtorMessage)
                      alert(linkCopiedAlert(languages.locale))
                    }}>
                    <img src='https://my-environment-assets.s3.eu-central-1.amazonaws.com/general/payments/secure-payment-icon.png' alt="Copy payment link to clipboard" width="100" height="100" />
                  </div>
                  <p className="font-weight-bold mt-2">{copyButtonLabel(languages.locale)}</p>
                </div>

                <div className="ml-5">
                  <a
                    className="d-flex justify-content-center align-items-center border border-white rounded"
                    style={{
                      transition: 'background-color 0.3s ease',
                      cursor: 'default'
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = '#D3D3D3'; // Light gray in hex
                      e.target.style.cursor = 'pointer';
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = '';
                      e.target.style.cursor = 'default';
                    }}
                    href={paydebtorMessage}
                    target="_blank">
                    <img src='https://my-environment-assets.s3.eu-central-1.amazonaws.com/general/payments/data-transfer-icon.png' alt="Open payment link in another tab" width="100" height="100" />
                  </a>
                  <p className="font-weight-bold mt-2">{openLinkButtonLabel(languages.locale)}</p>
                </div>
              </div>
            }
            {loading &&
              <div className="mt-2">
                {loadingLabel(languages.locale)} ...
              </div>
            }
          </div>
        </Modal>
      </div>
      {
        loading && <Spinner />
      }
    </Suspense>
  )
}

export default withHandleQuery([useQuotationStepsQuery, usePaymentSchemeQuery, useSignatureQuery, useHouseYearQuery, useCustomerQuery, useQuotationPdfQuery, useUpdateQuotationIsReadyMutation])(React.memo(Order))